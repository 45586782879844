<template>

    <div class="foot">

          <section class="counter" style="background: #5984a4;" v-if="summary!==undefined">
            <div class="container" style="padding-top: 1rem;">
                <div class="counter__content">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter__item">
                                <div class="counter__item__text" style="color: #f3f7f9;">
                                    <h2 class="counter_num" style="color: #f3f7f9;">{{animatedUserCount}}</h2>
                                    <p>註冊人數</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter__item second__item">
                                <div class="counter__item__text" style="color: #f3f7f9;">
                                    <h2 class="counter_num" style="color: #f3f7f9;">{{animatedMactCount}}</h2>
                                    <p>建立活動總數量</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter__item third__item">
                                <div class="counter__item__text" style="color: #f3f7f9;">
                                    <h2 class="counter_num" style="color: #f3f7f9;">{{animatedSigndetailsCount}}人次</h2>
                                    <p>累計參加總人次</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="counter__item four__item">
                                <div class="counter__item__text" style="color: #f3f7f9;">
                                    <h2 class="counter_num" style="color: #f3f7f9;;">{{animatedTotalScore}}枚</h2>
                                    <p>累計發放健康學分</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="text-white text-left text-lg-start" id="foots"  >
          <!-- Grid container -->
          <div class="container p-4">
            <!--Grid row-->
            <div class="row">
              <!--Grid column-->
              
              <div class="col-lg-6 col-md-12 mb-2 mb-md-0">
                <div style="padding: 1rem;">
                <h6 class="text-uppercase text-white">連江縣健康活動參與平台</h6>
                <p>
                宸緯資訊有限公司 客服電話 05-5572958  
                </p>
                服務信箱：glong168@gmail.com<br/>
                服務時間：週一至週五 Mon.-Fri. 09:00-18:00 
                </div>   
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->
          </div>
  <!-- Grid container -->

  <!-- Copyright -->
          <div class="text-center p-3" id="buttoner">
            © 2023 Copyright:
            <a class="text-white" href="#">宸緯資訊有限公司</a>
          </div>
  <!-- Copyright -->
      </footer>

    </div>
</template>
<script>
export default {
  name: 'Footbar',
  props: {
    msg: String,
    summary:Array,
  },
  data() {
    return {
        animatedUserCount: 0,
        animatedMactCount: 0,
        animatedSigndetailsCount: 0,
        animatedTotalScore: 0
    };
  },

  mounted() {
      setTimeout(() => {   
          if(this.summary!==undefined){
            if(this.summary[0].user_count!==undefined){
                this.animateValue('animatedUserCount', this.summary[0].user_count);
                this.animateValue('animatedMactCount', this.summary[0].mact_count);
                this.animateValue('animatedSigndetailsCount', this.summary[0].signdetails_count);
                this.animateValue('animatedTotalScore', this.summary[0].total_score);
            }
          }   
          }, 850);

  },
    methods: {
        animateValue(ref, target) {
            let currentValue = this[ref];
            const increment = target / 200;

            const update = () => {
                currentValue += increment;
                if (currentValue < target) {
                    this[ref] = Math.ceil(currentValue).toLocaleString('en-US');
                    requestAnimationFrame(update);
                } else {
                    this[ref] = target.toLocaleString('en-US');
                }
            };
            update();
        }
    }




}
</script>
<style scoped>
#foots{
  background: linear-gradient(45deg,#29536e 0%,#29536e 60%,#34607c 60%,#34607c 100%);
}
#buttoner {
  background-color: #0a3047;
  opacity:0.9;
}
</style>